import 'bootstrap';
import 'scripts/util.js';

document.addEventListener('DOMContentLoaded', () => {
  // do your setup here
  console.log('Initialized app foo');
  
  require('vendor.js');
  // require('scripts/util.js');
  


});

window.addEventListener('load', function() {

    // Get reference of gallery container
    var elementRef = document.getElementById('gallery');

    // Init gallery according to wanted format
   
});

document.addEventListener('DOMContentLoaded', () => {
  // do your setup here
  console.log('Initialized app foos');
  if ($('btn')) {
    console.log('jQuery works')
  }
});
