const gallery = require('gallery');

console.log('foo');




document.addEventListener('DOMContentLoaded', () => {

    // Get reference of gallery container
    // var elementRef = document.getElementById('gallery');

    // Init galleries found inside document
    gallery(document);
    
    // Init galleries found inside specific node
    gallery(document.querySelector('#gallery'));
   
});
